import { ColorPalette, createSitePalette } from "@libry-content/common";
import { createContext, ReactNode, useContext } from "react";
import { useCommonData } from "../components/layout/CommonDataProvider";

// Lager egen context og provider for sitePalette så vi kan beregne palatten en gang på toppnivå istedenfor hver gang den brukes (som ble voldsomt mange beregninger)
const PaletteContext = createContext<ColorPalette>(createSitePalette());

export const useSitePalette = () => useContext(PaletteContext);

export const PaletteProvider = (props: { children: ReactNode }) => {
  const { site } = useCommonData();
  const sitePlatte = createSitePalette(site);

  return <PaletteContext.Provider value={sitePlatte}>{props.children}</PaletteContext.Provider>;
};
